import { Carousel as CarouselAntd } from "antd"
import { useNavigate } from "react-router-dom"
import './index.css'
import { TextBtn } from "../../shared";
import IssuedWellnessTicket from "../IssuedWellnessTicket";

interface IProps {
    myIssuedWellnessTicketList: IIssuedWellnessTicketBasicResponseV2[];
}
const IssuedWellnessTicketCarousel = ({ myIssuedWellnessTicketList }: IProps) => {
    const navigate = useNavigate();

    return <>
        <div className="title/t1" style={{ paddingInline: "20px" }}>보유 정기권</div>
        {myIssuedWellnessTicketList.length === 0
            ? <div style={{
                backgroundColor: "var(--secondarys100)", borderRadius: "var(--radius4)",
                margin: '28px 20px 24px',
                padding: '61px 0',
                textAlign: 'center'
            }}>
                <div className="body/b2 secondary/s600">
                    보유하신 정기권이 없습니다
                </div>
                <TextBtn type='underline' textDecorationColor='' style={{ color: 'var(--secondarys800)', marginTop: 8 }} onClick={() => navigate('/wellness-ticket-group')} icon={false}>
                    정기권 구매하기
                </TextBtn>
            </div>
            : <div style={{ marginTop: '28px', marginBottom: '51px', paddingInline: myIssuedWellnessTicketList.length === 1 ? "20px" : "20px 0" }}>
                <CarouselAntd draggable autoplay autoplaySpeed={5000} style={{ marginBottom: '-15px' }} slidesToShow={myIssuedWellnessTicketList.length === 1 ? 1 : 1.024}>
                    {myIssuedWellnessTicketList.map(issuedWellnessTicket =>
                        <div className="issuedWellnessTicketWrap" key={issuedWellnessTicket.id}
                            onClick={() => navigate(`/issued-wellness-ticket/${issuedWellnessTicket.id}`)}>
                            <div style={{ marginLeft: myIssuedWellnessTicketList.length === 1 ? 0 : 12 }}>
                                <IssuedWellnessTicket issuedWellnessTicket={issuedWellnessTicket} />
                            </div>
                        </div>
                    )}
                </CarouselAntd>
            </div>
        }
    </>
}

export { IssuedWellnessTicketCarousel }