import { useEffect, useState } from "react";
import { BottomBtn, BottomSheet, DividerThin, InlineWellnessTicketReceipt, TicketCancleModal, WellnessTicketReceipt } from "../../shared"
import { formatPhoneNumber } from "../../utils";
import PayInfoSkeleton from "../../components/skeleton/pay/payInfoSkeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as CirleClose } from "../../assets/images/icon/circle-close.svg";
import { ReactComponent as CircleCheck } from "../../assets/images/icon/circle-check.svg";
import { TopBar } from "../../widgets";
import { checkEventCouponAvailableByCodeAndTicket, getEventCouponAvailableByCodeAndTicket, setUrl, getCreatePayInfoDataByMemberIdAndMemberMobileWellnessTicketId, getAllAvailableIssuedYogaCouponByMemberIdAndWellnessTicketId, createIssuedWellnessTicketAndPaymentByMemberDtoAndWellnessTicketIdAndOptionalEventCouponIdAndOptionalIssuedYogaCouponIdAndUsedPointWithoutMoney } from "../../service";
import { InputNumber } from "antd";
import { UseCouponByPayInfo } from "../../features";
import dayjs from "dayjs";

const PayInfo = () => {
    const [isInitLoading, setIsInitLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const location = useLocation();
    const { wellnessTicketId } = useParams();
    const [usePoint, setUsePoint] = useState<number>(0);
    const [ticketCancelModalStatus, setTicketCancelModalStatus] = useState<boolean>(false);
    const [targetPayInfoData, setTargetPayInfoData] = useState<IPayInfoBasicWebResponseV2>();
    const [payInfoData, setPayInfoData] = useState<IPayInfoBasicWebResponseV2>();
    const [eventCouponInfo, setEventCouponInfo] = useState<IEventCoupon>();
    const [couponId, setCouponId] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [yogaCouponModalStatus, setYogaCouponModalStatus] = useState<boolean>(false);
    const [conselingBranchModalStatus, setConselingBranchModalStatus] = useState<boolean>(false);
    const [selectIssuedYogaCoupon, setSelectIssuedYogaCoupon] = useState<IAvailableIssuedYogaCoupon>();
    const [availableIssuedYogaCouponList, setAvailableIssuedYogaCouponList] = useState<IAvailableIssuedYogaCoupon[]>([]);
    const [getIssuedYogaCoponloading, setGetIssuedYogaCoponloading] = useState<boolean>(false);
    const [selectedConselingBranch, setSelectedConselingBranch] = useState<"SEOLLEUNG" | "DOSAN" | "GANGNAM">("SEOLLEUNG");
    const [isInfoCheck, setIsInfoCheck] = useState<boolean>(false);
    const [isCancelCheck, setIsCancelCheck] = useState<boolean>(false);
    const [isPaying, setIsPaying] = useState<boolean>(false);
    const maxPoint = Math.min(targetPayInfoData?.memberTotalUsablePoint ?? 0, targetPayInfoData?.wellnessTicketDiscountedPrice ?? 0)
    useEffect(() => {
        if (!wellnessTicketId) return;
        getCreatePayInfoDataByMemberIdAndMemberMobileWellnessTicketId(wellnessTicketId)
            .then(res => { setTargetPayInfoData(res.data); setPayInfoData(res.data) })
            .catch(err => { console.error(err); })
            .finally(() => setIsInitLoading(false))
        setGetIssuedYogaCoponloading(true)
        getAllAvailableIssuedYogaCouponByMemberIdAndWellnessTicketId(wellnessTicketId)
            .then(res => setAvailableIssuedYogaCouponList(res.data))
            .catch(err => { console.error(err); })
            .finally(() => setGetIssuedYogaCoponloading(false))
    }, [])

    useEffect(() => {
        document.body.style.overflow = ticketCancelModalStatus ? 'hidden' : '';
        return () => {
            document.body.style.overflow = '';
        };
    }, [ticketCancelModalStatus])
    const selectYogaCouponClick = (issuedYogaCouponData?: IAvailableIssuedYogaCoupon) => {
        setCouponId('');
        setSelectIssuedYogaCoupon(issuedYogaCouponData);
        if (!payInfoData) return;
        let newPrice;
        if (!issuedYogaCouponData) {
            newPrice = payInfoData.wellnessTicketDiscountedPrice
        } else {
            newPrice = payInfoData.wellnessTicketDiscountedPrice - (payInfoData.wellnessTicketDiscountedPrice * issuedYogaCouponData.issuedYogaCouponDiscountValue * 0.01);
        }
        const newPainfoData = { ...payInfoData, wellnessTicketDiscountedPrice: newPrice, }
        setTargetPayInfoData(newPainfoData);
    }
    const clickCouponApply = async (couponCode: string) => {
        setSelectIssuedYogaCoupon(undefined);
        if (!couponCode) return alert("코드를 입력해 주세요");
        if (!wellnessTicketId) return;
        setLoading(true);
        const res = await getEventCouponAvailableByCodeAndTicket(couponCode, wellnessTicketId);
        setEventCouponInfo(res.data);
        if (res.data.id === 0) {
            const res = await checkEventCouponAvailableByCodeAndTicket(couponCode, wellnessTicketId);
            alert(res.data);
            setLoading(false);
            return;
        }
        setLoading(false);
        setYogaCouponModalStatus(false);
        if (!payInfoData) return;
        let newPrice;
        if (res.data.type === '비율') {
            newPrice = payInfoData.wellnessTicketDiscountedPrice - (payInfoData.wellnessTicketDiscountedPrice * res.data.discount);
        } else {
            newPrice = payInfoData.wellnessTicketDiscountedPrice - res.data.discount;
        }
        const newTicketData = { ...payInfoData, wellnessTicketDiscountedPrice: newPrice, }
        setCouponId(res.data.id.toString());
        setTargetPayInfoData(newTicketData);
    }

    const clickPaymentButton = () => {
        if (!targetPayInfoData) return;
        if (isPaying) return;
        const state = {
            defaultPrice: payInfoData?.wellnessTicketDiscountedPrice,
            price: usePoint ? targetPayInfoData.wellnessTicketDiscountedPrice - usePoint : targetPayInfoData.wellnessTicketDiscountedPrice,
            orderId: `${wellnessTicketId}-${dayjs().format('YYYYMMDDHHmmssZ').replaceAll('+', '').replaceAll(':', '')}`,
            orderName: `${targetPayInfoData.wellnessTicketName}`,
            couponId: couponId,
            issuedYogaCouponId: selectIssuedYogaCoupon?.issuedYogaCouponId,
            pointValue: usePoint ?? 0,
            selectedConselingBranch: selectedConselingBranch ? selectedConselingBranch : "SEOLLEUNG",
        }
        if (state.price === 0) {
            if (!wellnessTicketId) return;
            setIsPaying(true)
            createIssuedWellnessTicketAndPaymentByMemberDtoAndWellnessTicketIdAndOptionalEventCouponIdAndOptionalIssuedYogaCouponIdAndUsedPointWithoutMoney({ wellnessTicketId: wellnessTicketId, eventCouponId: eventCouponInfo?.id, usedPoint: usePoint ?? 0, issuedYogaCouponId: selectIssuedYogaCoupon?.issuedYogaCouponId, yogaCenterEnum: selectedConselingBranch ? selectedConselingBranch : "SEOLLEUNG" })
                .then(res => { console.log(res.data); navigate('/my') })
                .catch(e => console.error('e', e))
                .finally(() => setIsPaying(false));
            return;
        }
        setUrl(location.pathname)
        navigate('/checkout', { state: state })
    }

    const priceOnceCalc = () => {
        if (!payInfoData || !targetPayInfoData) return;
        const getPeriodUsableCnt = () => {
            const targetDate = dayjs().add(payInfoData.wellnessTicketUsableDate, 'd');
            switch (payInfoData.wellnessTicketFrequencyUnit) {
                case 'WEEK':
                    return targetDate.diff(dayjs(), 'week');
                case 'MONTH':
                    return targetDate.diff(dayjs(), 'month');
                case 'YEAR':
                    return targetDate.diff(dayjs(), 'year');
                default:
                    return 1;
            }
        }

        const totalUseableCnt = payInfoData.wellnessTicketType === 'COUNT'
            ? payInfoData.wellnessTicketTotalUsableCnt
            : getPeriodUsableCnt()

        return Number(((targetPayInfoData.wellnessTicketDiscountedPrice - usePoint) / totalUseableCnt).toFixed()).toLocaleString() + '원'
    }

    const convetFrequencyUnitKr = (wellnessTicketFrequencyUnit: string) => {
        if (wellnessTicketFrequencyUnit === 'WEEK') return '주'
        if (wellnessTicketFrequencyUnit === 'YEAR') return '연'
        if (wellnessTicketFrequencyUnit === 'MONTH') return '월'
        if (wellnessTicketFrequencyUnit === 'DAY') return '일'
        if (wellnessTicketFrequencyUnit === 'NOLIMIT') return ''
        return ''
    }

    return <>
        {isInitLoading && <PayInfoSkeleton />}
        <TopBar title="결제 확인" rightIcon={false} />
        <div style={{ padding: '24px var(--space20)' }}>
            {payInfoData &&
                <WellnessTicketReceipt wellnessTicket={{
                    name: payInfoData.wellnessTicketName,
                    price: payInfoData.wellnessTicketDiscountedPrice,
                    startColor: payInfoData.wellnessTicketStartColor,
                    endColor: payInfoData.wellnessTicketEndColor,
                    totalUsableCnt: payInfoData.wellnessTicketTotalUsableCnt,
                    textColor: payInfoData.wellnessTicketTextColor,
                    discountedPrice: payInfoData.wellnessTicketDiscountedPrice,
                    startDate: dayjs().format('YYYY-MM-DD'),
                    expireDate: dayjs().add(payInfoData.wellnessTicketUsableDate, 'd').format('YYYY-MM-DD')
                }}>
                    <div className="title/t1">회원 정보 확인</div>
                    <InlineWellnessTicketReceipt title={"이름"} content={payInfoData.memberName} style={{ marginTop: 'var(--space20)' }} />
                    <DividerThin />
                    <InlineWellnessTicketReceipt title={"연락처"} content={formatPhoneNumber(payInfoData.memberMobile)} />
                    <DividerThin />
                    <InlineWellnessTicketReceipt title={"이메일"} content={payInfoData.memberEmail} />

                    <div className="title/t1 base/black" style={{ marginTop: 'var(--space40)' }}>정기권 정보</div>
                    <InlineWellnessTicketReceipt title={"상담 지점"}
                        content={<BottomSheet
                            title={'지점 선택'}
                            isShowLabel
                            dataList={[{ id: 1, label: '선릉', value: 'SEOLLEUNG' }, { id: 2, label: '도산', value: 'DOSAN' }, { id: 3, label: '강남', value: 'GANGNAM' }]}
                            isOpen={conselingBranchModalStatus}
                            setIsOpen={setConselingBranchModalStatus}
                            value={selectedConselingBranch}
                            setValue={setSelectedConselingBranch} />
                        }
                        style={{ marginTop: 'var(--space20)' }} />
                    <DividerThin />
                    <InlineWellnessTicketReceipt title={"수강권명"} content={payInfoData.wellnessTicketName} />
                    <DividerThin />
                    <InlineWellnessTicketReceipt title={"사용기한"} content={dayjs().format('YYYY.MM.DD') + " - " + dayjs().add(payInfoData.wellnessTicketUsableDate, 'd').format('YYYY.MM.DD')} />
                    {(payInfoData.wellnessTicketFrequencyUnit && payInfoData.wellnessTicketFrequencyUnit !== 'NOLIMIT') && <>
                        <DividerThin />
                        <InlineWellnessTicketReceipt title={"사용제한"} content={`${convetFrequencyUnitKr(payInfoData.wellnessTicketFrequencyUnit)} ${payInfoData.wellnessTicketFrequencyUsableCnt}회`} />
                    </>}
                    <div className="title/t1 base/black" style={{ marginTop: 'var(--space40)' }}>결제 정보</div>
                    <InlineWellnessTicketReceipt title={"정기권 판매 금액"} content={payInfoData.wellnessTicketPrice ? payInfoData.wellnessTicketPrice.toLocaleString() + '원' : '0원'} style={{ marginTop: 'var(--space20)' }} />
                    <DividerThin />
                    {payInfoData.wellnessTicketDiscountedPrice !== payInfoData.wellnessTicketPrice && <>
                        <InlineWellnessTicketReceipt title={"할인 금액"} content={payInfoData.wellnessTicketDiscountCalcValue ? '-' + payInfoData.wellnessTicketDiscountCalcValue.toLocaleString() + '원' : '0원'} />
                        <DividerThin />
                    </>}
                    <InlineWellnessTicketReceipt
                        title={"포인트"}
                        style={{ alignItems: 'flex-start' }}
                        content={<div style={{ textAlign: 'right', position: 'relative' }}>
                            <div className="body/b2">사용 가능 <span className="primary/p800" >{payInfoData.memberTotalUsablePoint ? payInfoData.memberTotalUsablePoint?.toLocaleString() + '원' : '0원'}</span></div>
                            <InputNumber
                                style={{ width: '148px', marginTop: '12px', height: '32px' }}
                                min={0}
                                max={maxPoint}
                                value={usePoint}
                                placeholder="사용할 포인트"
                                onChange={(value: number | null) => value ? setUsePoint(value) : setUsePoint(0)}
                                controls={false}
                            />
                            <CirleClose width={16} height={16} stroke="var(--secondarys400)" onClick={() => { setUsePoint(0) }} style={{ position: 'absolute', cursor: 'pointer', top: 44, right: 16 }} />
                        </div>}
                    />
                    <DividerThin />
                    <InlineWellnessTicketReceipt
                        title={"쿠폰"}
                        style={{ paddingBlock: 'var(--space16)' }}
                        content={
                            <UseCouponByPayInfo
                                yogaCouponModalStatus={yogaCouponModalStatus}
                                setYogaCouponModalStatus={setYogaCouponModalStatus}
                                availableIssuedYogaCouponList={availableIssuedYogaCouponList}
                                selectYogaCouponClick={selectYogaCouponClick}
                                selectIssuedYogaCoupon={selectIssuedYogaCoupon}
                                clickCouponApply={clickCouponApply}
                                couponId={couponId}
                                loading={loading}
                                getIssuedYogaCoponloading={getIssuedYogaCoponloading}
                            />}
                    />

                    {(couponId && eventCouponInfo) && <InlineWellnessTicketReceipt title={eventCouponInfo.name} content={'-' + (payInfoData.wellnessTicketDiscountedPrice * eventCouponInfo.discount).toLocaleString() + '원'} styles={{ title: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }, content: { whiteSpace: 'nowrap' } }} />}
                    {selectIssuedYogaCoupon && <InlineWellnessTicketReceipt title={selectIssuedYogaCoupon.yogaCouponName} content={'-' + (payInfoData.wellnessTicketDiscountedPrice * selectIssuedYogaCoupon.issuedYogaCouponDiscountValue * 0.01).toLocaleString() + '원'} styles={{ title: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }, content: { whiteSpace: 'nowrap' } }} />}
                    <DividerThin />
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: 'var(--space20) var(--space12) 0' }} >
                        <div className="sub-title/st2 secondary/s800" >총 결제 금액</div>
                        <div className="sub-title/st3 base/black" >{(((targetPayInfoData?.wellnessTicketDiscountedPrice ?? 0) - (usePoint ?? 0))?.toLocaleString() ?? 0) + '원'}</div>
                    </div>
                    <InlineWellnessTicketReceipt title={"회당 금액"} content={priceOnceCalc()} />
                </WellnessTicketReceipt>}
            <div style={{ marginTop: 'var(--space16)' }}>
                <div style={{ display: 'flex', gap: 'var(--space12)', width: '244px', cursor: 'pointer' }} onClick={() => setIsInfoCheck(q => !q)}>
                    <CircleCheck width={20} height={20} stroke={isInfoCheck ? "var(--basewhite)" : "var(--secondarys400)"} fill={isInfoCheck ? "var(--baseblack)" : "var(--basewhite)"} style={{ paddingBlock: 'var(--space2)' }} />
                    <div className={"body/b2 base/black"}>
                        위 정보를 확인했으며 이에 동의합니다.
                    </div>
                </div>
                <div style={{ display: 'flex', gap: 'var(--space12)', width: '228px', marginTop: 8 }}>
                    <CircleCheck width={20} height={20} stroke={isCancelCheck ? "var(--basewhite)" : "var(--secondarys400)"} fill={isCancelCheck ? "var(--baseblack)" : "var(--basewhite)"}
                        style={{ paddingBlock: 'var(--space2)', cursor: 'pointer' }}
                        onClick={() => {
                            document.body.style.overflow = 'hidden';
                            setTicketCancelModalStatus(true)
                        }} />
                    <div style={{ display: 'flex', gap: 'var(--space8)' }}>
                        <div className={"body/b2 base/black"} style={{ cursor: 'pointer' }} onClick={() => {
                            document.body.style.overflow = 'hidden';
                            setTicketCancelModalStatus(true)
                        }}>
                            이용 약관에 동의합니다. <span style={{ color: '#737373', fontSize: 12, marginLeft: 5 }}>자세히 {'>'}</span>
                        </div>
                        <TicketCancleModal openModal={ticketCancelModalStatus} setOpenModal={setTicketCancelModalStatus} onOkClick={() => setIsCancelCheck(true)} />
                    </div>
                </div>
            </div>
        </div>
        <BottomBtn disabled={!(isInfoCheck && isCancelCheck && !isPaying) || maxPoint < usePoint} text={maxPoint < usePoint ? '포인트를 확인해주세요.' : isPaying ? '결제 중입니다.' : (((targetPayInfoData?.wellnessTicketDiscountedPrice ?? 0) - (usePoint ?? 0))?.toLocaleString() ?? 0) + ' 원 결제하기'} onClick={clickPaymentButton} />
    </>
}

export { PayInfo }