import { AxiosResponse } from 'axios';
import api from './Api'

export const getYogaCenterDetail = (id: string): Promise<AxiosResponse<IYogaCenterDetail, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/yoga-center/${id}`)
}
export const getUsedYogaCenterIdByMobile = (): Promise<AxiosResponse<IYogaCenterSelecList, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/yoga-center/modal`)
}
export const getAllYogaCenterIdAndName = (): Promise<AxiosResponse<IYogaCenterIdAndNameWebResponse[]>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/yoga-center/id-name/web/v2/`)
}