import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TicketByCenterIdSkeleton from "../../../components/skeleton/ticket/ticketByCenterIdSkeleton";
import { TopBar } from "../../../widgets";
import { BottomBtn } from "../../../shared";
import { getWellnessTicketGroupDetailByWellnessTicketGroupId } from "../../../service/wellnessTicketGroup";
import { WellnessTicketCardForWellnessTicketGroupDetail } from "../../../shared/card/wellnessTicketCardForWellnessTicketGroupDetail";
import { useQuery } from "@tanstack/react-query";

const WellnessTicketGroupDetail = () => {
    const navigate = useNavigate();
    const [selectedWellnessTicketId, setSelectedWellnessTicketId] = useState<number>();
    const { id } = useParams() as { id: string };

    const { data: wellnessTicketGroup, isPending } = useQuery({
        queryKey: ['wellnessTicketGroup', id],
        queryFn: () => getWellnessTicketGroupDetailByWellnessTicketGroupId(id),
        select: (res) => res.data,
        enabled: !isNaN(Number(id))
    });

    useEffect(() => {
        if (isNaN(Number(id))) {
            return navigate('/wellness-ticket-group')
        }
    }, [id])

    return <>
        {isPending && <TicketByCenterIdSkeleton />}
        <TopBar title={wellnessTicketGroup?.title} rightIcon={false} />
        <div style={{ padding: '24px var(--space20)' }}>
            {wellnessTicketGroup?.wellnessTicketList
                .filter((wellnessTicket: any) => wellnessTicket.isDisplay)
                .map((wellnessTicket: any) => <WellnessTicketCardForWellnessTicketGroupDetail key={wellnessTicket.id} wellnessTicket={wellnessTicket} isSelect={selectedWellnessTicketId === wellnessTicket.id} onClick={() => setSelectedWellnessTicketId(wellnessTicket.id)} />)}
        </div>
        <BottomBtn disabled={!selectedWellnessTicketId} to={selectedWellnessTicketId ? '/pay-info/' + selectedWellnessTicketId : ''} text="선택하기" />
    </>
}
export { WellnessTicketGroupDetail }