import { Flex } from "antd";
import dayjs from "dayjs";

interface IProps {
    issuedWellnessTicket: IIssuedWellnessTicketParam
}

interface IIssuedWellnessTicketParam {
    startDate: string;
    expireDate: string;
    remainingCnt: number;
    name: string;
    startColor: string;
    endColor: string;
    textColor: string;
}


const Count = ({ issuedWellnessTicket }: IProps) => {
    return (
        <div style={{ width: 'auto', maxHeight: 180, borderRadius: 4, background: `linear-gradient(135deg, ${issuedWellnessTicket.startColor}, ${issuedWellnessTicket.endColor})`, padding: '20px', color: issuedWellnessTicket.textColor }}>
            <div style={{ fontSize: 22, lineHeight: '26px', fontWeight: 600 }}>{issuedWellnessTicket.name}</div>

            <Flex style={{ marginTop: 66, alignItems: 'center', fontSize: 14 }} gap={8}>
                <div style={{ fontWeight: 600 }}>사용 기간</div>
                <div>{dayjs(issuedWellnessTicket.startDate).format('YYYY.MM.DD')} - {dayjs(issuedWellnessTicket.expireDate).format('YYYY.MM.DD')}</div>
                <div style={{ backgroundColor: `${issuedWellnessTicket.textColor}66`, fontSize: 12, borderRadius: 4, padding: '4px 8px', color: issuedWellnessTicket.textColor }}>
                    D-{dayjs(issuedWellnessTicket.expireDate).diff(dayjs(), 'day')}
                </div>
            </Flex>

            <div style={{ color: issuedWellnessTicket.textColor }}>잔여 횟수 {issuedWellnessTicket.remainingCnt}회</div>

        </div>
    )
}

export default Count;